import { Images } from "@/constants/images"
import { Flex, Menu, Text, createStyles } from "@mantine/core"
import Image from "next/image"
import { useRouter } from "next/router"
import { FC } from "react"

const langs = [
  {
    code: "en",
    countryCode: "EN",
    title: "English",
    icon: Images.enFlag,
  },
  {
    code: "vi",
    countryCode: "VN",
    title: "Tiếng Việt",
    icon: Images.viFlag,
  },
  {
    code: "cs",
    countryCode: "CZ",
    title: "Česky",
    icon: Images.csFlag,
  },
]

const useStyles = createStyles(theme => ({
  dropdown: {},
}))

interface LanguageSwitcherProps {
  withArrow?: boolean
}

const LanguageSwitcher: FC<LanguageSwitcherProps> = ({ withArrow = true }) => {
  const { classes, cx, theme } = useStyles()
  const router = useRouter()
  const onChange = (code: string) => {
    const { pathname, asPath, query } = router
    router.push({ pathname, query }, asPath, { locale: code })
  }

  const locale = router.locale ?? "en"

  return (
    <Menu withArrow={withArrow}>
      <Menu.Target>
        <Flex justify={"center"} align={"center"} className="cursor-pointer" gap={3}>
          <Image src={langs.find(e => e.code === locale)?.icon!} width={25} alt="vn-flag" />
          <Text ml={5}>{langs.find(e => e.code === locale)?.countryCode!}</Text>
          {withArrow && <Image src={Images.arrowDown} alt="arrow-down" />}
        </Flex>
      </Menu.Target>
      <Menu.Dropdown className={classes.dropdown}>
        {langs.map(e => (
          <Menu.Item
            key={e.code}
            onClick={() => onChange(e.code)}
            color={e.code === locale ? theme.colors.tdf[7] : theme.colors.black[0]}
          >
            <Flex align="center" gap={6}>
              <Image src={e.icon} width={25} alt="Flag" />
              <Text>{e.title}</Text>
            </Flex>
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  )
}

export default LanguageSwitcher
