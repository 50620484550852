import LanguageSwitcher from "@/components/Header/LanguageSwitcher"
import MobileHeader from "@/components/Header/MobileHeader"
import { Images } from "@/constants/images"
import { getNavigations } from "@/data/navigations"
import { Container, Flex, Grid, Group, MantineTheme, Text, createStyles, getStylesRef } from "@mantine/core"
import { useTranslation } from "next-i18next"
import Image from "next/image"
import Link from "next/link"
import { useRouter } from "next/router"
import { FC } from "react"

const useStyles = createStyles((theme: MantineTheme) => ({
  container: {
    boxShadow: "1px 6px 18px 1px #3a9ea917",
    position: "sticky",
    top: 0,
    zIndex: 2,
  },
  becomeBtn: {
    borderRadius: 100,
    height: 36,
    fontSize: 16,
    fontWeight: 500,
  },
  findBtn: {
    fontSize: 16,
    fontWeight: 500,
    borderRadius: 100,
    height: 36,
  },
  wrapper: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    boxShadow: "0 .5rem 1.25rem 0 rgba(0,0,0,.1)",
    background: "#FFFFFF",
    zIndex: 99,
  },
  nav: {
    listStyleType: "none",
    listStyle: "none",
    padding: 0,
    margin: 0,
    height: "100%",
  },
  menuItem: {
    height: "100%",
  },
  link: {
    ref: getStylesRef("link"),
    height: "100%",
    display: "flex",
    alignItems: "center",
    color: theme.colors.gray[8],
    fontWeight: 600,
    position: "relative",

    "&:hover": {
      "&:after": {
        width: "100%",
      },
    },

    "&:after": {
      position: "absolute",
      left: 0,
      bottom: 0,
      content: '""',
      height: "0.25rem",
      width: "0%",
      transition: "width .3s ease",
      backgroundColor: theme.colors[theme.primaryColor][7],
    },
  },
  active: {
    "&:after": {
      width: "100%",
    },
  },
  md: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
}))

export const Navigations = [
  { url: "/about-us", title: "Về chúng tôi" },
  { url: "/rental-service", title: "Dịch vụ Cho thuê" },
  { url: "/faqs", title: "FAQs" },
  { url: "/contact", title: "Liên hệ" },
]

interface HeaderProps {
  navigations?: Awaited<ReturnType<typeof getNavigations>>
}

export const Header: FC<HeaderProps> = ({ navigations }) => {
  const { pathname } = useRouter()

  const { t } = useTranslation("common")

  const { classes, cx } = useStyles()

  return (
    <div className={cx(classes.wrapper)}>
      <Container h={89}>
        <Grid columns={24} p={0} m={0} h={"100%"} className={classes.md}>
          <Grid.Col sm={4} span={0} p={0} m={0}>
            <Link key={1} href={"/"}>
              <Flex align={"center"} h={"100%"}>
                <Image src={Images.logo} width={128} alt="logo" />
              </Flex>
            </Link>
          </Grid.Col>
          <Grid.Col sm={18} span={0} p={0} m={0}>
            <ul role="navigation" className={classes.nav}>
              <Flex
                justify={"center"}
                align={"center"}
                p={0}
                m={0}
                h={"100%"}
                columnGap={"20px"}
                mr={{ base: "160px" }}
              >
                {navigations?.mainMenu?.map((e, i) => (
                  <li key={i} className={classes.menuItem}>
                    <Link
                      key={i}
                      href={e.url ?? ""}
                      className={cx(classes.link, { [classes.active]: e.url === pathname })}
                      style={{ padding: "0 20px" }}
                    >
                      <Text fw={400} fz={16}>
                        {e.title}
                      </Text>
                    </Link>
                  </li>
                ))}
              </Flex>
            </ul>
          </Grid.Col>
          <Grid.Col sm={2} span={0} p={0}>
            <Flex justify={"space-between"} align={"center"} h={"100%"}>
              {/* <Image src={Images.search} alt="search" /> */}
              <Group position="center">
                <LanguageSwitcher />
              </Group>
            </Flex>
          </Grid.Col>
        </Grid>
        <MobileHeader navigations={navigations} />
      </Container>
    </div>
  )
}
