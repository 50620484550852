import LanguageSwitcher from "@/components/Header/LanguageSwitcher"
import { MobileMenu } from "@/components/Header/MobileMenu"
import { Images } from "@/constants/images"
import { getNavigations } from "@/data/navigations"
import { createStyles, Grid } from "@mantine/core"
import Image from "next/image"
import Link from "next/link"
import { FC, useState } from "react"

const useStyles = createStyles(theme => ({
  sm: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },
  openMenu: {
    display: "block",
    height: "19px",
    alignItems: "center",
    cursor: "pointer",
    pointerEvents: "auto",
    color: "#f6f5fa",
    marginLeft: "5px",
    background: "none",
    border: "none",
    outline: "none",
  },
  openMenuBtn: {
    fontSize: "16px",
    lineHeight: "26px",
    cursor: "pointer",
    boxSizing: "border-box",
    pointerEvents: "auto",
    width: "24px",
    height: "18px",
    position: "relative",
    display: "block",
    color: "#18af4e",

    "& > i": {
      color: "#18af4e",
      boxSizing: "border-box",
      transition: "all .3s cubic-bezier(.645,.045,.355,1)",
      top: "50%",
      left: "0",
      marginTop: "-1px",
      width: "100%",
      height: "2px",
      background: "currentColor",
      position: "absolute",

      "&:before, &:after": {
        left: "50%",
        width: "100%",
        height: "100%",
        background: "inherit",
        content: '""',
        transition: "width .3s cubic-bezier(.645,.045,.355,1)",
        transform: "translate(-50%)",
        position: "absolute",
      },

      "&:before": {
        top: "-8px",
      },

      "&:after": {
        top: "8px",
      },
    },
  },
  logo: {
    maxHeight: "100%",
    textAlign: "center",
  },
  logoImg: {
    height: 60,
    width: "auto",
  },
  header: {
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    margin: 0,
  },
  languageSwitchBtn: {
    background: "none",
    border: "none",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,

    "> div": {
      color: theme.colors.black[0],
      marginLeft: 4,
    },
  },
}))

interface MobileHeaderProps {
  navigations?: Awaited<ReturnType<typeof getNavigations>>
}

const MobileHeader: FC<MobileHeaderProps> = ({ navigations }) => {
  const [isOpen, setIsOpen] = useState(false)

  const { classes, cx } = useStyles()
  return (
    <Grid columns={24} className={cx(classes.sm, classes.header)}>
      <Grid.Col span={4} p={0}>
        <button
          className={classes.openMenu}
          onClick={() => {
            setIsOpen(true)
          }}
        >
          <div className={classes.openMenuBtn}>
            <i />
          </div>
        </button>
      </Grid.Col>
      <Grid.Col span={16} className={classes.logo} p={0}>
        <Link href="/">
          <Image src={Images.logo} alt="logo" className={classes.logoImg} />
        </Link>
      </Grid.Col>
      <Grid.Col span={4} p={0}>
        <LanguageSwitcher withArrow={false} />
      </Grid.Col>
      <MobileMenu
        navigations={navigations}
        onClose={() => {
          setIsOpen(false)
        }}
        isOpen={isOpen}
      />
    </Grid>
  )
}

export default MobileHeader
