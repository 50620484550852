import { Button, createStyles } from "@mantine/core"
import { useTranslation } from "next-i18next"
import { FC, useRef } from "react"
import { CookieConsent } from "react-cookie-consent"

const useStyles = createStyles(theme => ({
  gdprWrapper: {
    ".cookies-info": {
      bottom: "0",
      boxShadow: "0 -2px 4px 0 rgba(0,0,0,.149)",
      fontFamily: "Roboto,Arial Regular,sans-serif",
      left: "0",
      minHeight: "100px",
      position: "fixed",
      right: "0",
      zIndex: 10002,
    },
    ".cookies-info__backdrop": {
      background: "#000",
      height: "100%",
      left: "0",
      opacity: 0.65,
      position: "fixed",
      top: "0",
      width: "100%",
      zIndex: -1,
    },
    ".cookies-info__container": {
      backgroundColor: "#172639",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    "@media screen and (min-width: 1764px)": {
      ".cookies-info__container": {
        backgroundImage: "url(/Styles/images/svg/alza-cookies-bg-desktop.svg)",
      },
    },
    ".cookies-info__inner": {
      WebkitBoxPack: "justify",
      msFlexPack: "justify",
      justifyContent: "space-between",
      margin: "auto",
      padding: "30px 0",
      width: "1310px",
      [theme.fn.smallerThan("sm")]: {
        width: "100%",
        flexDirection: "column",
      },
    },
    ".cookies-info__content,.cookies-info__inner": {
      display: ["-webkit-box", "-ms-flexbox", "flex"],
    },
    ".cookies-info__content": {
      WebkitBoxOrient: "vertical",
      WebkitBoxDirection: "normal",
      msFlexDirection: "column",
      flexDirection: "column",
      marginRight: "150px",
      [theme.fn.smallerThan("sm")]: {
        marginRight: "0",
      },
    },
    ".cookies-info__title": {
      color: "#f8f8f8",
      fontSize: "18px",
      fontWeight: 600,
      marginBottom: "20px",
    },
    ".cookies-info__intro": {
      color: "#fff",
      fontSize: "14px",
      fontWeight: 300,
      lineHeight: "25px",
      marginBottom: "20px",
    },
    ".cookies-info__more-block": {
      color: "#fff",
      cursor: "pointer",
      textDecoration: "none",
    },
    ".cookies-info__more-block svg": {
      fill: "#fff",
      marginBottom: "-3px",
      marginRight: "10px",
    },
    ".cookies-info__more-block:hover .cookies-info-more-label": {
      textDecoration: "underline",
    },
    ".cookies-info__more-block .cookies-info-more-label": { fontSize: "14px" },
    ".cookies-info__more-block .cookies-info-more-label--show": {
      display: "inline-block",
    },
    ".cookies-info__more-block .cookies-info-more-label--hide,.cookies-info__more-block.js-cookies-info-more--open .cookies-info-more-label--show":
      {
        display: "none",
      },
    ".cookies-info__more-block.js-cookies-info-more--open .cookies-info-more-label--hide": {
      display: "inline-block",
    },
    ".cookies-info__more-block.js-cookies-info-more--open svg": {
      marginBottom: "-5px",
      WebkitTransform: "rotate(180deg)",
      transform: "rotate(180deg)",
    },
    ".cookies-info__text": {
      fontFamily: "Roboto,Arial Regular,sans-serif",
      fontSize: "13px",
      lineHeight: 1.5,
    },
    ".cookies-info__text p": { margin: "0" },
    ".cookies-info__buttons": {
      WebkitBoxOrient: "vertical",
      WebkitBoxDirection: "normal",
      WebkitBoxPack: "center",
      msFlexPack: "center",
      display: ["-webkit-box", "-ms-flexbox", "flex"],
      msFlexDirection: "column",
      flexDirection: "column",
      justifyContent: "center",
    },
    ".cookies-info__button": {
      background: "#37b24d",
      WebkitBoxShadow: "0 1px 4px rgba(0,0,0,.149)",
      boxShadow: "0 1px 4px rgba(0,0,0,.149)",
      WebkitBoxSizing: "border-box",
      boxSizing: "border-box",
      color: "#fff",
      fontFamily: "Roboto,Arial Regular,sans-serif !important",
      fontSize: "14px",
      fontWeight: 700,
      letterSpacing: ".75px",
      padding: "10px 15px",
      whiteSpace: "nowrap",
      width: "100%",
      borderRadius: "4px",
      [theme.fn.smallerThan("sm")]: {
        textAlign: "center",
      },
    },
    ".cookies-info__button:hover": { background: "#37b24e", cursor: "pointer" },
    ".cookies-info__button:not(:first-child)": { marginTop: "10px" },
    ".cookies-info__button--white": { backgroundColor: "#fff", color: "#535252" },
    ".cookies-info__button--white:hover": { background: "#f2f2f2" },
    ".cookies-info__button--link": {
      WebkitBoxAlign: "center",
      msFlexAlign: "center",
      WebkitBoxPack: "center",
      msFlexPack: "center",
      alignItems: "center",
      background: "none",
      WebkitBoxShadow: "none",
      boxShadow: "none",
      display: ["-webkit-box", "-ms-flexbox", "flex"],
      justifyContent: "center",
      textDecoration: "none",
    },
    ".cookies-info__button--link:hover": {
      background: "none",
      textDecoration: "underline",
    },
    ".cookies-info.cols-3": { minHeight: "120px" },
    ".cookies-info.cols-3 .cookies-info__inner": {
      width: "1035px",
      [theme.fn.smallerThan("sm")]: {
        width: "100%",
        flexDirection: "column",
        padding: "2rem 1.25rem",
      },
    },
    ".cookies-info__ol-list, .cookies-info__subheadline": {
      color: "#fff",
    },
  },
}))

interface GDPRCookieProps {
  onAccept: () => void
  onReject: () => void
}

const GDPR: FC<GDPRCookieProps> = ({ onAccept, onReject }) => {
  const { classes } = useStyles()
  const { t } = useTranslation("common")

  return (
    <div className={classes.gdprWrapper}>
      <div className="js-cookies-info cookies-info cols-3">
        <div className="cookies-info__container">
          <div className="cookies-info__inner">
            <div className="cookies-info__content">
              <span className="cookies-info__title">
                <span style={{ verticalAlign: "inherit" }}>
                  <span style={{ verticalAlign: "inherit" }}>
                    {t("privacy.We care about your privacy", "We care about your privacy")}
                  </span>
                </span>
              </span>
              <span className="cookies-info__intro">
                <span style={{ verticalAlign: "inherit" }}>
                  <span style={{ verticalAlign: "inherit" }}>
                    {t(
                      "privacy.We, the company TAMDA GROUP, use cookies to ensure the functionality of the website and, with your consent, also to personalize the content of our website",
                      "We, the company TAMDA GROUP, use cookies to ensure the functionality of the website and, with your consent, also to personalize the content of our website",
                    )}
                    {". "}
                  </span>
                  <span style={{ verticalAlign: "inherit" }}>
                    {t(
                      "privacy.By clicking the 'I understand' button, you agree to the use of cookies and the transfer of data about website behavior to display targeted advertising on social networks and advertising networks on other websites",
                      "By clicking the 'I understand' button, you agree to the use of cookies and the transfer of data about website behavior to display targeted advertising on social networks and advertising networks on other websites",
                    )}
                    {". "}
                  </span>
                </span>
              </span>
            </div>
            <div className="cookies-info__buttons">
              <Button variant="filled" onClick={() => onAccept()} style={{ zIndex: 99 }}>
                <span style={{ verticalAlign: "inherit" }}>
                  <span style={{ verticalAlign: "inherit" }}>{t("privacy.I understand", "I understand")}</span>
                </span>
              </Button>
              <Button variant="default" onClick={() => onReject()} style={{ marginTop: "1rem", zIndex: 99 }}>
                <span style={{ verticalAlign: "inherit" }}>
                  <span style={{ verticalAlign: "inherit" }}>
                    {t("privacy.Reject everything", "Reject everything")}
                  </span>
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const GDPRCookie = () => {
  const { t } = useTranslation("common")
  const ref = useRef<CookieConsent>(null)
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept!!"
      declineButtonText="Reject"
      cookieName="tamdagroupGdpr"
      enableDeclineButton
      expires={150}
      ref={ref}
    >
      <GDPR onAccept={() => ref.current?.accept()} onReject={() => ref.current?.decline()} />
    </CookieConsent>
  )
}

export default GDPRCookie
