import GDPRCookie from "@/components/GDPR"
import { Images } from "@/constants/images"
import { getNavigations } from "@/data/navigations"
import { Container, Flex, Grid, MantineTheme, Text, createStyles } from "@mantine/core"
import { first, last } from "lodash"
import { useTranslation } from "next-i18next"
import { FacebookIcon, LinkedinIcon, TwitterIcon } from "next-share"
import Image from "next/image"
import Link from "next/link"
import { FC } from "react"

const useStyles = createStyles((theme: MantineTheme) => ({
  container: {
    backgroundColor: "#F7F7F7",
    position: "relative",
    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
  },
  footer: {
    flexDirection: "column",
    "& > img": {
      margin: "0 auto",
      marginTop: 47,
    },
    [theme.fn.smallerThan("sm")]: {
      padding: "0 1rem",
    },
  },
  title: {
    color: theme.colors[theme.primaryColor][6],
    fontSize: 16,
    fontWeight: 700,
    marginBottom: "1rem",
  },
  description: {
    fontWeight: 400,
    fontSize: 16,
    marginBottom: 0,
    color: "#000000",
  },
  office: {
    marginBottom: "1rem",
    alignItems: "center",
    "& > div": {
      marginBottom: 0,
    },
    "& > img": {
      marginRight: 16,
    },
  },
  HContainer: {
    padding: "0 29px 0 53px",
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    height: 68,
    top: 0,
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    justifyContent: "space-around",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: 8,
    [theme.fn.smallerThan("sm")]: {
      "div:nth-child(1)": {},
      "div:nth-child(2)": {},
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",

      height: "auto",
      padding: "1rem 2rem",
      "> div": {
        marginRight: 0,
      },
    },
  },
  HContact: {
    color: "#F08A21",
    alignItems: "center",
    "& > img": {
      color: "#F08A21",
      marginRight: 5,
    },
  },
  copyright: {
    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
  },
  logo: {
    [theme.fn.smallerThan("sm")]: {
      width: "50%",
      marginTop: "1rem",
    },
  },
  link: {
    marginBottom: ".725rem",
  },
}))

const HaveQuestion: FC = () => {
  const { classes } = useStyles()
  const { t } = useTranslation("common")

  return (
    <Container m={0} className={classes.HContainer}>
      <Text fw={400} fz={16} mr={47}>
        {t("Have a Question?", "Have a Question?")}
      </Text>
      <Flex className={classes.HContact} mr={40}>
        <Image src={Images.phoneYellow} alt="phone" />
        <Text>+420 773 313 868</Text>
      </Flex>

      <Flex className={classes.HContact}>
        <Image src={Images.mailYellow} alt="email" />
        <Text>info.usti@tamda.eu</Text>
      </Flex>
    </Container>
  )
}

interface FooterProps {
  navigations?: Awaited<ReturnType<typeof getNavigations>>
}

export const Footer: FC<FooterProps> = ({ navigations }) => {
  const { classes } = useStyles()
  const { t } = useTranslation("common")

  return (
    <div className={classes.container}>
      <Container>
        <HaveQuestion />
        <Grid p={0} m={0} className="">
          <Flex style={{ marginTop: "3rem", width: "100%", justifyContent: "center" }}>
            <Image
              src={Images.logo}
              alt="logo"
              className={classes.logo}
              style={{ maxWidth: "200px", height: "auto" }}
            />
          </Flex>
          <Grid className={classes.footer} p={0} m={0} w={"100%"}>
            <Grid mt={18} p={0} m={0}>
              <Grid.Col sm={5} span={12}>
                <Text className={classes.title}>{t("Office & Branch", "Office & Branch")}</Text>
                <Flex className={classes.office}>
                  <Image src={Images.location} alt="location" />
                  <Text className={classes.description}>Havířská 352/17, 400 10 Ústí nad Labem</Text>
                </Flex>
                <Flex className={classes.office}>
                  <Image src={Images.phone} alt="phone" />
                  <Link href={"tel:+420773313868"}>
                    <Text className={classes.description}>+420 773 313 868</Text>
                  </Link>
                </Flex>
                <Flex className={classes.office}>
                  <Image src={Images.mail} alt="email" />
                  <Link href={"mailto:info.usti@tamda.eu"}>
                    <Text className={classes.description}>info.usti@tamda.eu</Text>
                  </Link>
                </Flex>
              </Grid.Col>
              <Grid.Col sm={3} span={12}>
                <Text className={classes.title}>{first(navigations?.footerMenu)?.title}</Text>
                {first(navigations?.footerMenu)?.children?.map(e => (
                  <Link href={e?.url} target={e?.target ?? "_self"} className={classes.description} key={e?.id}>
                    <Text className={classes.link}>{e?.title}</Text>
                  </Link>
                ))}
              </Grid.Col>
              <Grid.Col sm={4} span={12}>
                <Text className={classes.title}>{last(navigations?.footerMenu)?.title}</Text>
                {last(navigations?.footerMenu)?.children?.map(e => (
                  <Link href={e?.url} target={e?.target ?? "_self"} className={classes.description} key={e?.id}>
                    <Text className={classes.link}>{e?.title}</Text>
                  </Link>
                ))}
              </Grid.Col>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <div className={classes.copyright}>
        <Container>
          <Flex
            h={70}
            p={0}
            m={0}
            align={"center"}
            justify={"space-between"}
            sx={theme => ({
              [theme.fn.smallerThan("sm")]: {
                padding: "1rem",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column-reverse",
                height: "auto",
                textAlign: "center",
              },
            })}
          >
            <Text
              fw={400}
              fz={14}
              sx={theme => ({
                [theme.fn.smallerThan("sm")]: {
                  fontSize: 12,
                  lineHeight: "1.2",
                  marginTop: 12,
                },
              })}
            >
              © {t("Copyright", { currentYear: new Date().getFullYear() })}
            </Text>
            <Flex gap={".8rem"}>
              <Link href={"https://facebook.com"} target="_blank">
                <FacebookIcon size={32} round />
              </Link>
              <Link href={"https://twitter.com"} target="_blank">
                <TwitterIcon size={32} round />
              </Link>
              <Link href={"https://linkedin.com"} target="_blank">
                <LinkedinIcon size={32} round />
              </Link>
            </Flex>
          </Flex>
        </Container>
        <GDPRCookie />
      </div>
    </div>
  )
}
