import { Images } from "@/constants/images"
import { getNavigations } from "@/data/navigations"
import { createStyles } from "@mantine/core"
import { useTranslation } from "next-i18next"
import Image from "next/image"
import Link from "next/link"

interface Navigation {
  url: string
  title: string
}

interface Props {
  navigations?: Awaited<ReturnType<typeof getNavigations>>
  isOpen: boolean
  onClose: () => void
}

const useStyles = createStyles(theme => ({
  menuRoot: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    background: "rgba(0,0,0,.6)",
    transition: "all .3s cubic-bezier(.645,.045,.355,1)",
    zIndex: 999,
    opacity: 0,
    visibility: "hidden",
  },
  visible: {
    visibility: "visible",
    opacity: 1,
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 80,
    position: "relative",
    borderBottom: `1px solid #e5e5e5`,
    padding: "0.5rem",
    " a": {
      " img": {
        maxWidth: "100px",
        height: "auto",
      },
    },
  },
  logo: {},
  closeBtn: {
    right: ".5rem",
    background: "none",
    width: "40px",
    height: "40px",
    position: "absolute",
    flexShrink: 0,
    padding: "0",
    border: "none",
    cursor: "pointer",
    "&:after": {
      transform: "rotate(45deg)",
    },
    "&:before": {
      transform: "rotate(-45deg)",
    },
    "&:before, &:after": {
      top: "19px",
      left: "8px",
      content: '""',
      width: "24px",
      height: "3px",
      transformOrigin: "50% 50%",
      transition: "all .3s cubic-bezier(.645,.045,.355,1)",
      position: "absolute",
      background: theme.colors.tdf[7],
    },
  },
  menu: {
    fontSize: "16px",
    lineHeight: "26px",
    visibility: "visible",
    boxSizing: "border-box",
    background: "#ffffff",
    width: "400px",
    maxWidth: "80%",
    height: "100%",
    textAlign: "left",
    transition: "all .3s",
    overflowY: "auto",
    cursor: "default",
    position: "relative",
    transform: "translateX(-100%)",
  },
  menuVisible: {
    transform: "none",
  },
  nav: {
    padding: "0.5rem",
  },
  ul: {
    listStyleType: "none",
    padding: 0,
    margin: 0,

    " li": {
      borderBottom: "1px solid #e5e5e5",

      " a": {
        padding: "1rem",
        fontWeight: 600,
        display: "block",
        color: theme.colors.black[0],
      },
    },
  },
}))

export const MobileMenu = (props: Props) => {
  const { t } = useTranslation("common")
  const { classes, cx } = useStyles()
  return (
    <div className={cx(classes.menuRoot, props.isOpen && classes.visible)}>
      <div className={cx(classes.menu, props.isOpen && classes.menuVisible)}>
        <div className={classes.header}>
          <div className={classes.logo}>
            <Link href="/">
              <Image src={Images.logo} alt="Logo" />
            </Link>
          </div>
          <button onClick={props.onClose} className={classes.closeBtn}></button>
        </div>
        <nav className={classes.nav} role="navigation">
          <ul className={classes.ul}>
            {props?.navigations?.mainMenu.map(e => (
              <li key={e.url}>
                <Link href={e.url ?? ""}>{e.title}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  )
}
