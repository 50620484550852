import arrowDown from "../statics/images/arrow-down.svg"
import centrum from "../statics/images/centrum.png"
import completelyFree from "../statics/images/completely-free.png"
import contact from "../statics/images/contact.png"
import copy from "../statics/images/copy.png"
import downThin from "../statics/images/down-thin.png"
import express from "../statics/images/express.png"
import csFlag from "../statics/images/flag-cs.png"
import enFlag from "../statics/images/flag-en.png"
import viFlag from "../statics/images/flag-vi.png"
import impex from "../statics/images/impex.png"
import location from "../statics/images/location.png"
import memberExpress from "../statics/images/logo-tamdaexpress.png"
import memberFoods from "../statics/images/logo-tamdafoods.png"
import memberImpex from "../statics/images/logo-tamdaimpex.png"
import memberMedia from "../statics/images/logo-tamdamedia.png"
import memberOc from "../statics/images/logo-tamdaoc.png"
import mailYellow from "../statics/images/mail-yellow.png"
import mail from "../statics/images/mail.png"
import media from "../statics/images/media.png"
import partner1 from "../statics/images/partners/1.png"
import partner2 from "../statics/images/partners/2.png"
import partner3 from "../statics/images/partners/3.png"
import partner4 from "../statics/images/partners/4.png"
import partner5 from "../statics/images/partners/5.png"
import partner6 from "../statics/images/partners/6.png"
import phoneYellow from "../statics/images/phone-yellow.png"
import phone from "../statics/images/phone.png"
import print from "../statics/images/print.png"
import quickRegistration from "../statics/images/quick-registration.png"
import search from "../statics/images/search.svg"
import logo from "../statics/images/tdf-logo.png"
import thumbnail from "../statics/images/thumbnail.png"
import whyChooseMe from "../statics/images/why-choose-me.png"

export const Images = {
  memberExpress,
  memberOc,
  memberFoods,
  memberMedia,
  memberImpex,
  partner6,
  partner5,
  partner4,
  partner3,
  partner2,
  partner1,
  logo,
  search,
  viFlag,
  enFlag,
  csFlag,
  thumbnail,
  arrowDown,
  centrum,
  impex,
  media,
  express,
  contact,
  mail,
  phone,
  location,
  phoneYellow,
  mailYellow,
  quickRegistration,
  completelyFree,
  downThin,
  print,
  copy,
  whyChooseMe,
}
